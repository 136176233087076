/**
 * Creates a debounced version of a function that will only execute after a specified delay has passed since the last time it was invoked.
 * If immediate is set to true, the function will be executed immediately the first time it is invoked.
 *
 * @template T - An array type extending Array<unknown>, representing the argument types of the function to be debounced.
 * @template U - The return type of the function to be debounced.
 *
 * @param fn - The function to be debounced. It can take any number of arguments of any type and return any type.
 * @param [wait=250] - The amount of time in milliseconds to wait before executing the debounced function.
 * @param [immediate=false] - If true, the function will be executed immediately the first time it is invoked.
 * @returns A debounced version of the input function with the same argument and return types, plus a `cancel` method and an `isScheduled` property.
 *          The `isScheduled` property indicates whether a function execution is currently scheduled (true) or not (false).
 */
export default function useDebounce<T extends Array<unknown>, U>(
  fn: (...args: T) => U,
  wait = 250,
  immediate = false
): ((...args: T) => U) & DebouncedCtx {
  let timeout: ReturnType<typeof setTimeout> | undefined;
  let immediated = false

  function debounced(...args: T) {
    const later = () => {
      timeout = undefined
      debounced.isScheduled = false;
      fn(...args)
    }

    clearTimeout(timeout)

    if (!immediated && immediate) {
      debounced.isScheduled = false;
      fn(...args)
      immediated = true;
    } else {
      debounced.isScheduled = true;
      timeout = setTimeout(later, wait)
    }
  }

  debounced.cancel = () => {
    clearTimeout(timeout)
    debounced.isScheduled = false;
  }

  debounced.isScheduled = false;

  return debounced as ((...args: T) => U) & DebouncedCtx
}

type DebouncedCtx = { isScheduled: boolean, cancel: () => void }
